import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Pipeable APIs",
  "subtitle": "An introduction to pipeable APIs in fp-ts",
  "date": "2021-06-03T00:00:00.000Z",
  "layout": "post",
  "draft": false,
  "tags": ["javascript", "typescript", "functional programming", "fp-ts"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Pipeable APIs provide flexibility for adding new behavior and creating new functions for working with data types. With the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{` function from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fp-ts`}</code>{`, you can construct usages with good inference.`}</p>
    <h2>{`Data flow`}</h2>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{` function takes a value as it's first parameter, and then takes a list of functions which are sequentially called on that value. Each function receives the data that was returned from the previous step, and the last value is returned from the whole expression.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// n is 5`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// n is 10`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// final result is 20`}</span></code></pre>{`
      `}</div>
    <p>{`The type of the value in the chain doesn't necessarily stay the same type, for example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Uses min/max to ensure n is between min and max`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`clamp`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` min`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` max`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{` 

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"foo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`s`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`string`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// s is "foo"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` s `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"bar"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`s`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`string`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// s is "foobar"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// n is 6`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// n is 12`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`clamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// result is 10`}</span></code></pre>{`
      `}</div>
    <p>{`This could be cleaned up a bit without the explanatory comments and annotations:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"foo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`s`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` s `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"bar"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`s`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`clamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <h2>{`Specializing Functions`}</h2>
    <p>{`Let's play around with the functions here and provide names for some of the common operations:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`concat`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`s1`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` s2`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`string`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` s1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` s2
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`len`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`Array`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token builtin"
            }}>{`unknown`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`add`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` b`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` b
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`multiply`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` b`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` b`}</code></pre>{`
      `}</div>
    <p>{`And use those in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{` chain:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"foo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`s`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`concat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"bar"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`s`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`len`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`multiply`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`clamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`This doesn't really improve on the previous example at all, but let's try to rearrange the parameters a bit, and `}<em parentName="p">{`curry`}</em>{` the functions:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`concat`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`s2`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`string`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`s1`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`string`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` s1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` s2
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`add`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`b`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` b
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`multiply`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`b`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` b
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`clamp`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`min`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` max`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span></code></pre>{`
      `}</div>
    <p>{`Note the reversal of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`s1`}</code>{`/`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`s2`}</code>{` parameters, and the arrangement of the parameters of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`clamp`}</code>{`. This may seem odd at first, but it's a deliberate decision to make application in a pipe easier.`}</p>
    <p>{`Using the new curried versions of each function we arrive at:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"foo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`s`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`concat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"bar"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`s`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`len`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`multiply`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`clamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`An interesting pattern has emerged... Every anonymous function we've used in the pipe takes the value passed to it as the last argument in its own argument list. In JavaScript, you can replace the expression `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`s => <expression>(s)`}</code>{` with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<expression>`}</code>{`. So, for example, you can replace:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`s => concat("bar")(s)`}</code>{` with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`concat("bar")`}</code>{`,`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`s => len(s)`}</code>{` with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`len`}</code>{`,`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`n => multiply(2)(n)`}</code>{` with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`multiply(2)`}</code>{`, and`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`n => clamp(0, 10)(n)`}</code>{` with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`clamp(0, 10)`}</code>{`.`}</p>
    <p>{`Let's make those replacements in our snippet:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"foo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`addStr`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"bar"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  len`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`multiply`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`clamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`What we arrive at is a syntax that's very close to method chaining, but has a few additional benefits in extensibility.`}</p>
    <h2>{`In fp-ts`}</h2>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fp-ts`}</code>{` library heavily utilizes this pattern, as most functions are fashioned specifically to be used with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{`. Let's take a look at this snippet which uses some standard JS array methods:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` myNumbers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

myNumbers
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`filter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Now, compare this to using fp-ts's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Array`}</code>{` module:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/Array'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` pipe `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/function'`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  myNumbers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`filter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`myNumbers`}</code>{` array is passed through to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A.map`}</code>{` function, and then the returned value is passed to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A.filter`}</code>{` function. The value returned from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A.filter`}</code>{` is the final result returned from the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{` call. This snippet resembles it's chainable counterpart, and it's due to how the functions were designed.`}</p>
    <h2>{`Implementing the pipeable pattern`}</h2>
    <p>{`Implementing a pipeable API is just a matter of arranging the parameters in the right order. Take a look at these function signatures from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fp-ts`}</code>{`'s `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Array`}</code>{` module:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}>{`filter`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`boolean`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`as`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`Array`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
map`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`as`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`Array`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre>{`
      `}</div>
    <p>{`Notice anything in common? Both functions take the value they're operating on `}<em parentName="p">{`last`}</em>{`, in a parameter list by itself.`}</p>
    <p>{`This was done specifically to make it easier to use in the context of a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{` chain. Here, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`filter`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{` can be used in a pipe the same way they'd have been used in a method chain.`}</p>
    <h2>{`Extensibility`}</h2>
    <p>{`Since the only requirement for a function to be used in a pipe chain is that the last parameter list be solely the value it's operating on, it's trivial to add custom functions that can be interspersed with other functions. For example, we can define a function that works on arrays by making the last parameter list take an array:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`partition`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`boolean`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Either`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span></code></pre>{`
      `}</div>
    <p>{`Since the last parameter is an array, we can inject this function anywhere inside of a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{` chain with an array:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  myNumbers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`partition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`It's almost as if we extended the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Array`}</code>{` type to include the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`partition`}</code>{` function! This would be quite difficult with a chainable-style API. `}</p>
    <h2>{`Inference`}</h2>
    <p>{`When the subject of your function is generic, and the types of the other parameters depend on the type of the subject, using the function in a point free way helps with inference. `}</p>
    <p>{`Consider the following usage which, while correct,  doesn't type check:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` myNumbers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`myNumbers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`The type of the parameter `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`n`}</code>{` depends on the type of the array we pass it in the second argument list (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ns`}</code>{`). In order to fix this usage, you'd have to explicitly note the type parameter in the anonymous function:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ns`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`This is because Typescript's inference works left to right, so in order to resolve the type of the expression `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A.map(n => n + 1)(ns)`}</code>{` it must first resolve the type of the expression `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A.map(n => n + 1)`}</code>{`. Unfortunately, it doesn't have enough information in that context to be able to know that you will eventually pass an array of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`number`}</code>{`s (even though it's immediately after!).`}</p>
    <p>{`Now consider using this with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  myNumbers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Typescript will resolve the type of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ns`}</code>{` (which is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Array<number>`}</code>{`), `}<em parentName="p">{`before`}</em>{` resolving the type of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A.map(n => n + 1)`}</code>{`. The type of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{` signifies that the second parameter be a function that takes the first parameter: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe(myNumbers, <function that takes Array<number>>)`}</code>{` with this knowledge, the compiler knows that the anonymous function we supply to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{` will take a number, and so Typescript will infer it for us.`}</p>
    <p>{`If instead we tried:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  myNumbers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`We'd be back to square one, and we'd need to explicitly annotate the type of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`n`}</code>{`.`}</p>
    <h2>{`Discoverability`}</h2>
    <p>{`A common lament of pipeable APIs is that they aren't as "discoverable" as chainable APIs. This refers to the use of an autocomplete feature in IDEs, where you can type an expression, like: "`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`myNumbers.`}</code>{`" and your IDE will provide you a list of methods that are available on arrays.`}</p>
    <p>{`Since a pipeable API uses free-standing functions as opposed to methods, this isn't as straightforward. A technique that's often used is to import the free-standing functions into a short identifier namespace, for example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fp-ts/Array'`}</span></code></pre>{`
      `}</div>
    <p>{`Now, when you type the expression "`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A.`}</code>{`" your IDE's autocomplete will provide you with a list of those free-standing functions that operate on arrays.`}</p>
    <p>{`Of course, this isn't as nice as method chaining, but the tradeoff of extensibility is perhaps worth it.`}</p>
    <h2>{`Pipe vs flow`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`flow`}</code>{` is another function provided by `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fp-ts`}</code>{` which is similar to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{`, but is used in fully point-free implementations. Instead of taking a value and applying functions to that value successively, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flow`}</code>{` takes a list of functions and composes them together, returning a new function which will invoke the functions passed to flow. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flow`}</code>{` is essentially like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`compose`}</code>{`, except backwards, applying the leftmost function first. For example, let's suppose we have three functions, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`f`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`g`}</code>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h`}</code>{`, and we wish to apply them in order, like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`h`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`g`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`First, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`f`}</code>{` will be applied to the argument `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{`, then `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`g`}</code>{` will be applied to the return of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`f`}</code>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h`}</code>{` applied to the return of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`g`}</code>{`. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flow`}</code>{` can take these functions and build another function, combining them all:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` f_then_g_then_h `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`flow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`f`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` g`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` h`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`f_then_g_then_h`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`As a general rule, whenever you see an expression like: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`s => pipe(s, ...)`}</code>{`, it can logically be replaced with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flow(...)`}</code>{`. However, this replacement can't always practically be done due to the way Typescript's inference works. Like with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pipe`}</code>{`, using functions with a type argument is when it starts to fall over:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`flow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`filter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`This expression produces a `}<em parentName="p">{`logically`}</em>{` correct function that filters an array of numbers, and then adds one to each, but it doesn't typecheck, since there's no such array in sight. The type of the expression `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A.filter(n => n > 1)`}</code>{` is checked first, and the type parameter corresponding to the type of values in the array defaults to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`unknown`}</code>{`.`}</p>
    <p>{`Where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flow`}</code>{` `}<em parentName="p">{`does`}</em>{` work is in cases where Typescript already has the type information it needs. If we assign this expression to a variable with the type annotated, Typescript can suddenly resolve the type:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`modNumbers`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`as`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`Array`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`flow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`filter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`n`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <h2>{`The future`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/tc39/proposal-pipeline-operator"
      }}>{`pipeline operator proposal`}</a>{` is currently at stage 1 (which unfortunately means that it won't be added to typescript). If accepted, pipeable APIs benefit from getting some dedicated syntax, whre we can replace our original snippet:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"foo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`addStr`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"bar"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  len`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`multiply`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`clamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`with:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token string"
          }}>{`"foo"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`addStr`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"bar"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` len
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`multiply`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`clamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      